<template>
  <!--  修改邮箱    -->
  <a-modal
    :visible="visible"
    :title="showSafe ? '安全验证' : '修改邮箱'"
    centered
    :destroyOnClose="true"
    @cancel="close"
  >
    <!--   修改邮箱form  -->
    <a-form
      v-if="showSafe"
      ref="safeRef"
      :model="form.safeForm"
      :rules="safeRules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 16 }"
      :validate-trigger="['change', 'blur']"
    >
      <a-form-item label="原手机号">
        <div>{{ mobile }}</div>
      </a-form-item>
      <a-form-item name="oldCode" label="验证码">
        <a-row type="flex">
          <a-col :flex="1">
            <a-input
              v-model:value="form.safeForm.oldCode"
              placeholder="请输入验证码"
            />
          </a-col>
          <a-col class="ml-10">
            <a-button
              type="primary"
              :disabled="btn.disabled"
              ghost
              @click="handleGetCode"
            >
              {{ btn.title }}
            </a-button>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>

    <a-form
      v-else
      ref="safeRef"
      :model="form.safeForm"
      :rules="safeRules"
      :label-col="{ span: 5 }"
      :wrapper-col="{ span: 16 }"
      :validate-trigger="['change', 'blur']"
    >
      <a-form-item name="account" label="新邮箱地址">
        <a-input
          autocomplete="off"
          v-model:value="form.safeForm.account"
          :placeholder="'请输入新邮箱地址'"
        />
      </a-form-item>
      <a-form-item name="code" label="验证码">
        <a-row type="flex">
          <a-col :flex="1">
            <a-input
              v-model:value="form.safeForm.code"
              placeholder="请输入验证码"
            />
          </a-col>
          <a-col class="ml-10">
            <a-button
              type="primary"
              :disabled="btnT.disabled"
              ghost
              @click="handleGetCodeT"
            >
              {{ btnT.title }}
            </a-button>
          </a-col>
        </a-row>
      </a-form-item>
    </a-form>

    <template v-slot:footer>
      <a-button @click="close">取消</a-button>

      <a-button v-if="showSafe" type="primary" @click="gotoOk">
        下一步
      </a-button>
      <a-button v-else type="primary" @click="handleOk"> 确认 </a-button>
    </template>
  </a-modal>
</template>

<script>
import { Col, Form, message, Row } from 'ant-design-vue';
import { onMounted, reactive, ref, toRefs, watch } from 'vue';
// import { useRouter } from 'vue-router';
import { useStore } from 'vuex';
// import personalApi from '@/api/personal';
import { validEmail } from '@/utils/validation';
// import loginApi from '@/api/login';
import personalApi from '@/api/personal';

export default {
  components: {
    AForm: Form,
    AFormItem: Form.Item,
    ARow: Row,
    ACol: Col,
  },
  props: {
    visible: Boolean,
    init: Object,
  },
  setup(props, context) {
    const formRef = ref();
    const safeRef = ref();
    // const router = useRouter();
    const store = useStore();
    const userId = store.state.account.user.id;
    const state = reactive({
      visiblePE: false,
      showSafe: true,
      loading: false,
      // safeForm: { account: '', code: '', oldCode: '' },
      timer: null,
      timerT: null,
      showVal: null,
      btn: {
        disabled: false,
        title: '获取验证码',
      },
      btnT: {
        disabled: false,
        title: '获取验证码',
      },
      activeUpload: '',
      mobile: '',
    });
    const form = reactive({
      sex: '',
      safeForm: { account: '', code: '', oldCode: '' },
      id: userId ? userId : '',
      // area: null,
      userName: '',
      avatar: '',
    });

    watch(
      () => props.init,
      value => {
        console.log('value', value);
        state.mobile = value.description;
      }
    );

    const required = { required: true, whitespace: true, message: '不能为空' };
    // 基本信息校验

    onMounted(() => {});

    return {
      ...toRefs(state),
      form,
      formRef,
      safeRef,
      userId,
      safeRules: {
        account: [
          required,
          {
            validator: (rule, value) => {
              if (value) {
                if (validEmail(value)) {
                  return Promise.resolve();
                }
                return Promise.reject('请输入合法的邮箱地址');
              }
              return Promise.reject();
            },
          },
        ],
        code: required,
        oldCode: required,
      },

      gotoOk() {
        safeRef.value.validate().then(() => {
          // 校验验证码是否正确
          personalApi
            .verifyCodeByUserId('', {
              userId: userId,
              verifyCode: form.safeForm.oldCode,
            })
            .then(res => {
              if (res) {
                state.showSafe = false;
                // 点击一下步需要清空验证码和清除定时器
                clearInterval(state.timer);
                state.btn = { disabled: false, title: '获取验证码' };
                form.safeForm.oldCode = '';
              }
              // else {
              //   return message.error('验证码错误，请重新输入')
              // }
            });
        });
      },
      close() {
        context.emit('fnOk', false);
        state.showSafe = true;
        // state.mobile = '',
        clearInterval(state.timer);
        clearInterval(state.timerT);
        form.safeForm = { account: '', code: '', oldCode: '' };
        state.loading = false;
      },

      handleGetCode() {
        // safeRef.value.validateFields('account').then(() => {
        state.btn = { disabled: true, title: '重新获取(60s)' };
        let count = 60;
        state.timer = setInterval(() => {
          if (count === 1) {
            state.btn = { disabled: false, title: '获取验证码' };
            clearInterval(state.timer);
            state.timer = null;
          } else {
            count--;
            state.btn.title = `重新获取(${count}s)`;
          }
        }, 1000);
        // 根据用户id获取手机号验证码
        personalApi.sendSmsVerifyCodeByUserId('', userId).then(res => {
          if (res) {
            message.success('验证码已发送，请注意查收');
          }
        });

        // });
      },

      handleGetCodeT() {
        safeRef.value.validate('account').then(() => {
          state.btnT = { disabled: true, title: '重新获取(60s)' };
          let count = 60;
          state.timerT = setInterval(() => {
            if (count === 1) {
              state.btnT = { disabled: false, title: '获取验证码' };
              clearInterval(state.timerT);
              state.timerT = null;
            } else {
              count--;
              state.btnT.title = `重新获取(${count}s)`;
            }
          }, 1000);

          let parmas = {
            email: form.safeForm.account,
            userId: userId,
            verifyCode: form.safeForm.code,
          };
          console.log('parmas', parmas);
          personalApi
            .sendEmailVerifyCode('', parmas)
            .then(res => {
              if (res) {
                message.success('验证码已发送，请注意查收');
              }
            })
            .catch(() => {
              clearInterval(state.timerT);
              state.btnT = { disabled: false, title: '获取验证码' };
            });
        });
      },

      handleOk() {
        safeRef.value.validate().then(() => {
          if (state.loading) return;
          state.loading = true;

          let parmas = {
            email: form.safeForm.account,
            userId: userId,
            verifyCode: form.safeForm.code,
          };
          personalApi
            .updateOrBindUserEmail('', parmas)
            .then(res => {
              if (res) {
                message.success('操作成功');
                clearInterval(state.timer);
                clearInterval(state.timerT);
                state.btnT = { disabled: false, title: '获取验证码' };
                // 请求成功或者失败时关闭
                context.emit('fnOk', false);
                state.loading = false;
                state.showSafe = true;
                form.safeForm = { account: '', code: '', oldCode: '' };
              }
            })
            .catch(() => {
              state.loading = false;
            });
        });
      },
    };
  },
};
</script>

<style scoped></style>
